import React from "react";
import "./Footer1.css";
import { Link } from "react-router-dom";
import companyLogo from "./EMLOGO.png";
const Footer = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        className="footer-waves"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
          />
        </defs>
        <g className="waves">
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="0"
            fill="#d2093c"
            fillOpacity="0.2"
          />
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="3"
            fill="#d2093c"
            fillOpacity="0.5"
          />
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="6"
            fill="#d2093c"
            fillOpacity="0.9"
          />
        </g>
      </svg>
      <footer className="footer">
        <div className="footer-info">
          <div className="footer-section">
            <h3 className="footer-heading">Contact Us</h3>
            <p>
              <a href="mailto:info@nandedcitynews.com">
                Email: info@nandedcitynews.com
              </a>
            </p>
            <p>
              <a href="tel:+918055337333">Phone: +91 8055337333</a>
            </p>
            <p>
              <a
                href="https://www.google.com/maps?q=123+Nanded,+Maharashtra,+India"
                target="_blank"
                rel="noopener noreferrer"
              >
                Address: Nanded Maharashtra India
              </a>
            </p>
          </div>

          <div className="footer-section">
            <h3 className="footer-heading">Quick Links</h3>
            <ul className="footer-links">
              <li>
                <Link to="/about">आमची टीम</Link>
              </li>
              <li>
                <Link to="/contact">संपर्क</Link>
              </li>
              <li>
                <Link to="/maharashtra">महाराष्ट्र</Link>
              </li>
              <li>
                <Link to="/marathwada">मराठवाडा</Link>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h3 className="footer-heading">Follow Us</h3>
            <div className="footer-socials">
              <a
                href="https://www.facebook.com/NandedCityNews/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              {/* <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a> */}
              <a
                href="https://www.instagram.com/nandedcitynews"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
        <a
          href="https://emacronsolutions.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-logo-attribution-EM"
        >
          <img
            src={companyLogo}
            alt="Company Logo"
            className="company-logo-EM"
          />
          <p className="footer-attribution-EM">Created by Emacron Solutions</p>
        </a>
      </footer>
    </div>
  );
};

export default Footer;
