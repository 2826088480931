import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Sports.css";
import InstagramPosts from "../Homepage/InstagramPosts/InstagramPosts";
import axios from "../../utils/axiosInstance";
import defaultImage from "../../Assets/LOGO-NAME.png";

const Sports = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/Sports");
        if (response.data && response.data.length > 0) {
          // Reverse the news order to display the latest news first
          setNewsData(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="news-container-Sports">
      <h1 className="category-title">Sports</h1>
      {newsData.length > 0 ? (
        <>
          <div className="main-news-Sports">
            <Link
              to={`/news/${newsData[0].news_id}`}
              className="main-news-link"
            >
              <img
                src={newsData[0].image_url || defaultImage}
                alt={newsData[0].title}
              />
              <h2>{newsData[0].title}</h2>
            </Link>
          </div>
          <div className="side-news-Sports">
            {newsData.slice(1).map((news) => (
              <Link
                to={`/news/${news.news_id}`}
                key={news.id || news.news_id}
                className="side-news-link"
              >
                <div className="side-news-item-Sports">
                  <img src={news.image_url || defaultImage} alt={news.title} />
                  <p>{news.title}</p>
                </div>
              </Link>
            ))}
          </div>
        </>
      ) : (
        <div className="no-news-message">No news items available.</div>
      )}
    </div>
  );
};

export default Sports;
