import React from "react";
import Header from "./Pages/header/Header";
import Footer from "./Pages/Footer/Footer";
import "./Layout.css";
// import Translate from "./Pages/Translate/Translate";
const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <main className="mainContent">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
