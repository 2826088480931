import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axiosInstance";
import "./NewsDetail.css";
import defaultImage from "../../Assets/LOGO-NAME.png";

const NewsDetail = () => {
  const { id } = useParams(); // Get the news ID from the URL
  const [news, setNews] = useState(null);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const response = await axios.get(`/news/${id}`);
        setNews(response.data);
        console.log("News detail:", response.data);
      } catch (error) {
        console.error("Error fetching news detail:", error);
      }
    };

    fetchNewsDetail();
  }, [id]);

  if (!news) return <p>Loading...</p>;

  return (
    <div className="news-detail">
      <h2 className="news-detail__title">{news.title}</h2>
      {/* Conditionally render the image if news.image_url is available */}
      {news.image_url && (
        <img
          className="news-detail__image"
          src={news.image_url || defaultImage}
          alt={news.title}
        />
      )}
      <p className="news-detail__content">{news.content}</p>
      {/* <p className="news-detail__categories">
        Categories: {news.categories.map((cat) => cat.category_name).join(", ")}
      </p> */}
    </div>
  );
};

export default NewsDetail;
