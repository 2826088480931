import React, { useEffect, useRef, useState } from "react";
import styles from "./PhoneComponent.module.css";
import InstagramEmbed from "./InstagramEmbed";
import axios from "../../../utils/axiosInstance";

const PhoneComponent = () => {
  const [postUrls, setPostUrls] = useState([]); // State for Instagram post URLs
  const scrollContainerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Fetch Instagram posts from the backend
  const fetchInstagramPosts = async () => {
    try {
      const response = await axios.get("/instagramposts");
      // Reverse the order of the posts to display the most recent first
      setPostUrls(response.data.reverse()); // Assuming response.data contains the URLs directly
      console.log("Instagram post URLs:", response.data); // Logs the original response
    } catch (error) {
      console.error("Failed to fetch Instagram posts:", error);
    }
  };

  // Start scrolling the content
  const startScrolling = () => {
    if (scrollIntervalRef.current) return;

    const scroll = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy(0, 1);
        if (
          scrollContainerRef.current.scrollTop +
            scrollContainerRef.current.clientHeight >=
          scrollContainerRef.current.scrollHeight
        ) {
          scrollContainerRef.current.scrollTop = 0;
        }
      }
      setTimeout(() => {
        scrollIntervalRef.current = requestAnimationFrame(scroll);
      }, 30);
    };
    scrollIntervalRef.current = requestAnimationFrame(scroll);
  };

  // Stop scrolling the content
  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      cancelAnimationFrame(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    fetchInstagramPosts();
    startScrolling();
    return () => stopScrolling(); // Clean up on unmount
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.outsideBorder}>
        <div className={styles.silencer}></div>
        <div className={styles.volumeUp}></div>
        <div className={styles.volumeDown}></div>
        <div className={styles.buttonOn}></div>
        <div className={styles.insideBorder}>
          {/* Camera */}
          <div className={styles.camera}>
            <div className={styles.cameraDot}>
              <div className={styles.cameraDot2}></div>
              <div className={styles.cameraDot3}></div>
            </div>
            <div className={styles.cameraSpeaker}></div>
          </div>

          <div className={styles.trInfo}>
            <div className={styles.dots}>...</div>
            <div className={styles.battery}>
              <div className={styles.bar}></div>
              <div className={styles.dot}></div>
            </div>
          </div>

          {/* Date */}

          {/* Torch */}
          <div
            className={styles.instagrampostswrapper}
            onMouseEnter={stopScrolling}
            onMouseLeave={startScrolling}
          >
            <div
              ref={scrollContainerRef}
              className={styles.instagrampostscontent}
            >
              {postUrls.length > 0 ? (
                postUrls.map((postUrl, index) => (
                  <InstagramEmbed key={index} postUrls={[postUrl.post_url]} />
                ))
              ) : (
                <p>No posts available.</p> // Display message if no posts are found
              )}
            </div>
          </div>
          {/* Camera Outer */}

          {/* Bottom Line */}
          <div className={styles.bottomLine}></div>
        </div>
      </div>
    </section>
  );
};

export default PhoneComponent;
