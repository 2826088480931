import React, { useState, useEffect } from "react";
import "./GooeyMarquee.css"; // Import your CSS file
import axios from "../../../utils/axiosInstance"; // Adjust the path as necessary

const GooeyMarquee = () => {
  const [news, setNews] = useState([]);

  // Fetch marquee news from the backend
  const fetchMarqueeNews = async () => {
    try {
      const response = await axios.get("/marquee-news");
      // Reverse the array to show the most recent first
      setNews(response.data.map((item) => item.headline).reverse());
    } catch (error) {
      console.error("Error fetching marquee news:", error);
    }
  };

  useEffect(() => {
    fetchMarqueeNews();
  }, []);

  return (
    <div className="marquee-content">
      <div className="marquee">
        <div className="marquee_blur" aria-hidden="true">
          <p className="marquee_text">
            {news.length > 0 ? (
              news.map((item, index) => (
                <span
                  key={index}
                  style={{ color: index % 2 === 0 ? "black" : "red" }}
                >
                  {item} |
                </span>
              ))
            ) : (
              <span>No news available</span>
            )}
          </p>
        </div>
        <div className="marquee_clear">
          <p className="marquee_text">
            {news.length > 0 ? (
              news.map((item, index) => (
                <span
                  key={index}
                  style={{ color: index % 2 === 0 ? "black" : "red" }}
                >
                  {item} |
                </span>
              ))
            ) : (
              <span>No news available</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GooeyMarquee;
