import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Marathwada.css"; // Import the new CSS file
import axios from "../../utils/axiosInstance";
import defaultImage from "../../Assets/LOGO-NAME.png";
import InstagramPosts from "../Homepage/InstagramPosts/InstagramPosts";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting
import PhoneComponent from "../Homepage/InstagramPosts/PhoneComponent";

const Marathwada = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/Marathwada");
        if (response.data && response.data.length > 0) {
          // Reverse the news data so the most recent one comes first
          setNewsData(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  const truncateContent = (content) => {
    const maxLength = 500;
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };
  return (
    <div className="news-container-insta">
      <div className="marathwada-heading-container">
        <div className="Politics-line">
          <div className="Politics-header">
            <h1>मराठवाडा</h1>
          </div>

          {/* <div className="Politics-animation">
        <img src={animationImage} alt="EPS converted image" />
      </div> */}
        </div>
        <div className="main-news-Marathwada">
          {newsData.length > 0 ? (
            <>
              <div className="featured-news-marathwada">
                <Link
                  to={`/news/${newsData[0].news_id}`}
                  className="featured-link-marathwada"
                >
                  <img
                    className="featured-image-marathwada"
                    src={newsData[0].image_url || defaultImage}
                    alt={newsData[0].title}
                  />
                  <div className="featured-content-marathwada">
                    <h2 className="featured-title-marathwada">
                      {newsData[0].title}
                    </h2>
                    <span className="marathwada-news-date">
                      {" "}
                      {formatDistanceToNow(new Date(newsData[0].created_at), {
                        addSuffix: true,
                      })}
                    </span>
                    <p className="featured-description-marathwada">
                      {truncateContent(newsData[0].content)}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="other-news-Marathwada">
                {newsData.slice(1).map((news) => (
                  <Link
                    to={`/news/${news.news_id}`}
                    key={news.id}
                    className="news-item-Marathwada"
                  >
                    <img
                      className="news-image-marathwada"
                      src={news.image_url || defaultImage}
                      alt={news.title}
                    />
                    <p className="news-title">{news.title}</p>
                  </Link>
                ))}
              </div>
            </>
          ) : (
            <div className="no-news-message">No news items available.</div>
          )}
        </div>
      </div>
      {/* <InstagramPosts /> */}
      <PhoneComponent />
    </div>
  );
};

export default Marathwada;
