import React from "react";
import ScrollingNews from "./ScrollingNews/ScrollingNews";
import "./Home.css";
import GooeyMarquee from "./GooeyMarquee/GooeyMarquee";
import NewsComponent from "./News/NewsComponent";
import Politics from "../Politics/Politics";
import Sports from "../Sports/Sports";
import Crime from "../Crime/Crime";
import Marathwada from "../Marathwada/Marathwada";
import Maharashtra from "../Maharashtra/Maharashtra";
import NandedCity from "../Nanded-City/NandedCity";
import NandedRural from "../Nanded-Rural/NandedRural";
import { useInView } from "../../hooks/useInView";
import NewsSlider from "./Slider/NewsSlider";

const Home = () => {
  const [setGooeyRef, gooeyInView] = useInView();
  const [setNewsRef, newsInView] = useInView();
  const [setPoliticsRef, politicsInView] = useInView();
  const [setMarathwadaRef, marathwadaInView] = useInView();
  const [setMaharashtraRef, maharashtraInView] = useInView();
  const [setNandedCityRef, nandedCityInView] = useInView();
  const [setNandedRuralRef, nandedRuralInView] = useInView();
  const [setSportsRef, sportsInView] = useInView();
  const [setCrimeRef, crimeInView] = useInView();

  return (
    <div>
      {/* <div
        ref={setGooeyRef}
        className={gooeyInView ? "component-fade-in-up" : ""}
      >
        <NewsSlider />
      </div> */}
      <div
        ref={setGooeyRef}
        className={gooeyInView ? "component-fade-in-up" : ""}
      >
        <GooeyMarquee />
      </div>
      <div className="home-page">
        <div
          ref={setNewsRef}
          className={newsInView ? "component-delayed-fade-in" : ""}
        >
          <NewsComponent />
        </div>
        <div
          ref={setPoliticsRef}
          className={politicsInView ? "component-zoom-in-opacity" : ""}
        >
          <ScrollingNews />
        </div>
      </div>
      <div
        ref={setPoliticsRef}
        className={politicsInView ? "component-fade-in-up" : ""}
      >
        <Politics />
      </div>
      <div
        ref={setMarathwadaRef}
        className={marathwadaInView ? "component-zoom-in-opacity" : ""}
      >
        <Marathwada />
      </div>
      <div
        ref={setMaharashtraRef}
        className={maharashtraInView ? "component-fade-in-up" : ""}
      >
        <Maharashtra />
      </div>
      <div
        ref={setNandedCityRef}
        className={nandedCityInView ? "component-zoom-in-opacity" : ""}
      >
        <NandedCity />
      </div>
      <div
        ref={setNandedRuralRef}
        className={nandedRuralInView ? "component-delayed-fade-in" : ""}
      >
        <NandedRural />
      </div>
      {/* <div
        ref={setSportsRef}
        className={sportsInView ? "component-fade-in-up" : ""}
      >
        <Sports />
      </div> */}
      <div
        ref={setCrimeRef}
        className={crimeInView ? "component-delayed-fade-in" : ""}
      >
        <Crime />
      </div>
    </div>
  );
};

export default Home;
