import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Politics.css";
import axios from "../../utils/axiosInstance";
import animationImage from "../../Assets/vote.png";
import defaultImage from "../../Assets/LOGO-NAME.png";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting
import { FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";

const Politics = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/Politics");
        if (response.data && response.data.length > 0) {
          // Reverse the articles to show the latest first
          setArticles(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  const truncateContent = (content, wordLimit = 18) => {
    const words = content.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return content;
  };
  return (
    <div className="Politics-big-container">
      {" "}
      <div className="Politics-container">
        <div className="Politics-line">
          <div className="Politics-header">
            <h1>राजकीय घडामोडी</h1>
          </div>

          {/* <div className="Politics-animation">
        <img src={animationImage} alt="EPS converted image" />
      </div> */}
        </div>
        <div className="Politics-grid">
          {articles.map((article) => (
            <div
              className="Politics-item Politics-with-image"
              key={article.news_id || article.id} // Using a more reliable unique key
            >
              <Link to={`/news/${article.news_id}`} className="Politics-link">
                <img
                  src={article.image_url || defaultImage}
                  alt={article.title}
                  className="Politics-image"
                />

                <p className="Politics-title">{article.title}</p>
                <span className="politicle-news-date">
                  {" "}
                  {formatDistanceToNow(new Date(article.created_at), {
                    addSuffix: true,
                  })}
                </span>
                <p className="Politics-content">
                  {truncateContent(article.content)}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>{" "}
      <div className="social-media-cards">
        {/* Instagram Card */}
        <div className="social-card instagram-card">
          <div className="icon-wrapper">
            <FaInstagram className="social-icons" />
            <svg className="circle" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
          <h3 className="social-title">Instagram</h3>
          <p className="username">@nandedcitynews</p>
          <p className="followers-count">269K Followers</p>
          <a
            href="https://www.instagram.com/nandedcitynews/"
            className="follow-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow
          </a>
        </div>

        {/* Facebook Card */}
        <div className="social-card facebook-card">
          <div className="icon-wrapper">
            <FaFacebookF className="social-icons" />
            <svg className="circle" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
          <h3 className="social-title">Facebook</h3>
          <p className="username">@nandedcitynews</p>
          <p className="followers-count">101K Followers</p>
          <a
            href="https://www.facebook.com/NandedCityNews"
            className="follow-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow
          </a>
        </div>

        {/* Twitter Card */}
        <div className="social-card twitter-card">
          <div className="icon-wrapper">
            <FaTwitter className="social-icons" />
            <svg className="circle" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
          <h3 className="social-title">Twitter</h3>
          <p className="username">@nandedcitynews</p>
          {/* <p className="followers-count">669 Followers</p> */}
          <a
            href="https://x.com/NandedCityNews"
            className="follow-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow
          </a>
        </div>
      </div>
    </div>
  );
};

export default Politics;
