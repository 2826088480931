import React, { useState, useEffect } from "react";
import "./NewsManager.css";
import axios from "../../utils/axiosInstance";

const NewsManager = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMarquee, setIsMarquee] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [marqueeNewsList, setMarqueeNewsList] = useState([]); // Added for marquee news
  const [editMode, setEditMode] = useState(false);
  const [editNewsId, setEditNewsId] = useState(null);
  const [breakingNewsCount, setBreakingNewsCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("All");

  useEffect(() => {
    fetchCategories();
    fetchNews();
    fetchMarqueeNews(); // Fetch marquee news
  }, []);

  useEffect(() => {
    updateBreakingNewsCount();
  }, [newsList]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get("/news");
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const fetchMarqueeNews = async () => {
    try {
      const response = await axios.get("/marquee-news");
      setMarqueeNewsList(response.data); // Set marquee news list
    } catch (error) {
      console.error("Error fetching marquee news:", error);
    }
  };

  const updateBreakingNewsCount = () => {
    const breakingNews = newsList.filter((news) =>
      news.categories.some((cat) => cat.category_name === "Breaking")
    );
    setBreakingNewsCount(breakingNews.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imageFile && imageFile.size > 0.5 * 1024 * 1024) {
      setErrorMessage(
        `The file size should not exceed 500kb. Use <a href="https://tinypng.com/" target="_blank" rel="noopener noreferrer">TinyPNG</a> to reduce file size.`
      );
      return; // Stop form submission if the file is too large
    }
    // Check if any category exceeds the news limit
    // Check the number of news items in the "Breaking" and "Sports" categories
    const breakingNewsCountInList = newsList.filter((news) =>
      news.categories.some((cat) => cat.category_name === "Breaking")
    ).length;

    const sportsNewsCountInList = newsList.filter((news) =>
      news.categories.some((cat) => cat.category_name === "Sports")
    ).length;

    // Check if any of the selected categories have 8 or more news items
    const exceedsLimit = selectedCategories.some((category) => {
      if (category === "Breaking" && breakingNewsCountInList >= 6) {
        return true;
      }
      if (category === "Sports" && sportsNewsCountInList >= 6) {
        return true;
      }
      return false;
    });

    if (exceedsLimit) {
      setErrorMessage(
        "You cannot add more news to categories with 6 or more items. Please delete old news first."
      );
      return; // Prevent form submission
    }
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      if (imageFile) formData.append("image", imageFile);
      formData.append("categories", JSON.stringify(selectedCategories));
      formData.append("is_scrolling", isScrolling);
      formData.append("is_marquee", isMarquee);
      console.log(formData);
      if (editMode) {
        await axios.put(`/news/${editNewsId}`, formData);
        setEditMode(false);
        setEditNewsId(null);
      } else {
        await axios.post("/news", formData);
      }

      resetForm();
      fetchNews();
      fetchMarqueeNews(); // Re-fetch marquee news after adding/editing
    } catch (error) {
      setErrorMessage("Error saving news item.");
      console.error("Error saving news:", error);
    }
  };

  const deleteNews = async (newsId) => {
    try {
      await axios.delete(`/news/${newsId}`);
      fetchNews();
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  const deleteMarqueeNews = async (newsId) => {
    // Added delete function for marquee news
    try {
      await axios.delete(`/marquee-news/headline/${newsId}`);
      fetchMarqueeNews(); // Re-fetch marquee news after deletion
    } catch (error) {
      console.error("Error deleting marquee news:", error);
    }
  };

  const handleEdit = (news) => {
    setTitle(news.title);
    setContent(news.content);
    setSelectedCategories(news.categories.map((cat) => cat.category_name));
    setIsScrolling(news.is_scrolling);
    setIsMarquee(news.is_marquee);
    setEditMode(true);
    setEditNewsId(news.news_id);
    setErrorMessage("");
  };

  const resetForm = () => {
    setTitle("");
    setContent("");
    setImageFile(null);
    setSelectedCategories([]);
    setIsScrolling(false);
    setIsMarquee(false);
    setErrorMessage("");
  };

  const handleTabClick = (categoryName) => {
    setSelectedTab(categoryName);
  };

  return (
    <div className="news-manager">
      <h2 className="news-manager__title">
        {editMode ? "Edit News" : "Add News"}
      </h2>
      {errorMessage && (
        <div
          id="error-message"
          style={{}}
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        ></div>
      )}
      <form className="news-manager__form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <textarea
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />
        <div>
          <label>Categories:</label>
          {categories.map((category) => (
            <div
              key={category.category_name}
              className="news-manager__category"
            >
              <input
                type="checkbox"
                checked={selectedCategories.includes(category.category_name)}
                onChange={(e) => {
                  const selected = e.target.checked;
                  setSelectedCategories((prevSelected) =>
                    selected
                      ? [...prevSelected, category.category_name]
                      : prevSelected.filter(
                          (name) => name !== category.category_name
                        )
                  );
                }}
              />
              <label>{category.category_name}</label>
            </div>
          ))}
        </div>
        <div className="news-manager__category">
          <label>
            <input
              type="checkbox"
              checked={isScrolling}
              onChange={(e) => setIsScrolling(e.target.checked)}
            />
            Scrolling
          </label>
          <label>
            <input
              type="checkbox"
              checked={isMarquee}
              onChange={(e) => setIsMarquee(e.target.checked)}
            />
            Marquee
          </label>
        </div>
        <button className="news-manager__button" type="submit">
          {editMode ? "Update" : "Add"} News
        </button>
      </form>

      {/* Tabs for Categories */}
      <div className="news-manager__tabs">
        <button
          className={`news-manager__tab ${
            selectedTab === "All" ? "active" : ""
          }`}
          onClick={() => handleTabClick("All")}
        >
          All
        </button>
        {categories.map((category) => (
          <button
            key={category.category_name}
            className={`news-manager__tab ${
              selectedTab === category.category_name ? "active" : ""
            }`}
            onClick={() => handleTabClick(category.category_name)}
          >
            {category.category_name}
          </button>
        ))}
      </div>

      {/* Filtered News List by Selected Tab */}
      <div className="news-manager__list">
        <h3>Regular News</h3>
        {newsList
          .filter(
            (news) =>
              selectedTab === "All" ||
              news.categories.some((cat) => cat.category_name === selectedTab)
          )
          .map((news) => (
            <div key={news.news_id} className="news-manager__news-item">
              <h3>{news.title}</h3>
              <p>{news.content}</p>
              <img
                src={news.image_url}
                alt={news.title}
                className="news-manager__image"
              />
              <button
                className="news-manager__button"
                onClick={() => handleEdit(news)}
              >
                Edit
              </button>
              <button
                className="news-manager__button news-manager__button--delete"
                onClick={() => deleteNews(news.news_id)}
              >
                Delete
              </button>
            </div>
          ))}
      </div>

      {/* Marquee News List */}
      <div className="marquee-news-section">
        <h3>Marquee News</h3>
        {marqueeNewsList.length > 0 ? (
          marqueeNewsList.map((news) => (
            <div key={news.news_id} className="news-manager__news-item">
              <h3>{news.headline}</h3>
              <p>{news.content}</p>
              <img
                src={news.image_url}
                alt={news.title}
                className="news-manager__image"
              />
              <button
                className="news-manager__button news-manager__button--delete"
                onClick={() => deleteMarqueeNews(news.headline)} // Added delete for marquee news
              >
                Delete Marquee
              </button>
            </div>
          ))
        ) : (
          <p>No marquee news available.</p>
        )}
      </div>
    </div>
  );
};

export default NewsManager;
//patti logo name
// remove round of nanded city news
// photo text change position and size
// logo change
// short white in navbar
//navbar change
//slow slider
//remove verification
//whatsapp
//use logo
