import React from "react";
import "./TopBar.css";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const TopBar = () => {
  return (
    <div className="topbar">
      <div className="topbar-content">
        {/* Social Media Links */}
        <div className="topbar-social">
          <a
            href="https://www.facebook.com/NandedCityNews/"
            className="social-icon"
            aria-label="Facebook"
          >
            <FaFacebook />
          </a>
          <a
            href="https://x.com/NandedCityNews"
            className="social-icon"
            aria-label="Twitter"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.instagram.com/nandedcitynews/?hl=en"
            className="social-icon"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
        </div>

        {/* Company Email */}
        <div className="topbar-email">
          <a href="mailto:contact@company.com">info@nandedcitynews.in</a>
        </div>

        {/* Phone Number */}
        <div className="topbar-phone">
          <a href="tel:+918055337333">+91 8055337333</a>
        </div>

        <div className="topbar-since">
          <a href="tel:+918055337333">Since 2012</a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
