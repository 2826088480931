// src/hooks/useInView.js
import { useEffect, useState } from "react";

export const useInView = (threshold = 0.1) => {
  const [isInView, setIsInView] = useState(false);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold }
    );

    observer.observe(ref);

    return () => observer.unobserve(ref);
  }, [ref, threshold]);

  return [setRef, isInView];
};
