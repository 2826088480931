import React, { useEffect } from "react";
import "./InstagramEmbed.css"; // Import the CSS file

const InstagramEmbed = ({ postUrls }) => {
  useEffect(() => {
    // Load the Instagram embed script
    const script = document.createElement("script");
    script.src = "//www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Ensure postUrls is an array
  const urls = Array.isArray(postUrls) ? postUrls : [postUrls];

  return (
    <div className="instagram-embed-container">
      {urls.map((url, index) => (
        <blockquote
          key={index}
          className="instagram-media"
          data-instgrm-permalink={url}
          data-instgrm-version="14"
        >
          <div>
            <a
              href={url}
              className="instagram-placeholder"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </blockquote>
      ))}
    </div>
  );
};

export default InstagramEmbed;
