import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./InstagramPosts.css"; // Updated CSS file name
import axios from "../../../utils/axiosInstance";
import InstagramEmbed from "./InstagramEmbed"; // Import the InstagramEmbed component

function InstagramPosts() {
  const [postUrls, setPostUrls] = useState([]); // State for Instagram post URLs
  const scrollContainerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Fetch Instagram posts from the backend
  const fetchInstagramPosts = async () => {
    try {
      const response = await axios.get("/instagramposts");
      // Reverse the order of the posts to display the most recent first
      setPostUrls(response.data.reverse()); // Assuming response.data contains the URLs directly
      console.log("Instagram post URLs:", response.data); // Logs the original response
    } catch (error) {
      console.error("Failed to fetch Instagram posts:", error);
    }
  };

  // Start scrolling the content
  const startScrolling = () => {
    if (scrollIntervalRef.current) return;

    const scroll = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy(0, 1);
        if (
          scrollContainerRef.current.scrollTop +
            scrollContainerRef.current.clientHeight >=
          scrollContainerRef.current.scrollHeight
        ) {
          scrollContainerRef.current.scrollTop = 0;
        }
      }
      setTimeout(() => {
        scrollIntervalRef.current = requestAnimationFrame(scroll);
      }, 30);
    };
    scrollIntervalRef.current = requestAnimationFrame(scroll);
  };

  // Stop scrolling the content
  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      cancelAnimationFrame(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  useEffect(() => {
    fetchInstagramPosts();
    startScrolling();
    return () => stopScrolling(); // Clean up on unmount
  }, []);

  return (
    <div className="instagram-posts-container">
      <h1 className="instagram-posts-title">Instagram Posts</h1>
      <div
        className="instagram-posts-wrapper"
        onMouseEnter={stopScrolling}
        onMouseLeave={startScrolling}
      >
        <div ref={scrollContainerRef} className="instagram-posts-content">
          {postUrls.length > 0 ? (
            postUrls.map((postUrl, index) => (
              <InstagramEmbed key={index} postUrls={[postUrl.post_url]} />
            ))
          ) : (
            <p>No posts available.</p> // Display message if no posts are found
          )}
        </div>
      </div>
    </div>
  );
}

export default InstagramPosts;
