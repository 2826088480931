import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Logo from "./LOGO-NAME.png";
import verified from "./icons8-blue-tick.svg";
// import Translate from "../Translate/Translate";
import logoName from "./logo-name-name.png";
import TopBar from "./TopBar";
const Header = () => {
  const [selected, setSelected] = useState("home");
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef(null);

  // Preload the logo image
  useEffect(() => {
    const img = new Image();
    img.src = Logo; // Preload the logo image
  }, []);

  useEffect(() => {
    const updateIndicator = () => {
      const selectedItem = document.querySelector(`.nav-item.${selected}`);
      if (selectedItem && navRef.current && window.innerWidth > 768) {
        const { offsetLeft, offsetWidth } = selectedItem;
        setIndicatorStyle({
          left: offsetLeft,
          width: offsetWidth,
        });
      } else {
        setIndicatorStyle({});
      }
    };

    updateIndicator();
    window.addEventListener("resize", updateIndicator);

    return () => {
      window.removeEventListener("resize", updateIndicator);
    };
  }, [selected]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="header-container">
      <div className="header">
        <TopBar />

        <div className="container-header">
          <div className="header-container">
            <div className="company-name-div">
              <svg width="400" height="100" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M50 0 H300 Q340 21 300 68 H50 Z"
                  fill="white"
                  stroke="white"
                  stroke-width="5"
                />
              </svg>

              <Link to="/" className="company-name-header" onClick={closeMenu}>
                <img
                  src={logoName}
                  alt="LifeNStartups Logo"
                  className="company-logo"
                />
              </Link>
              <div className="nanded-jag">
                <h1>
                  <span className="nanded">नांदेड ला जगाशी </span>
                  <br></br>
                  <span className="jag">जोडणारे</span>
                </h1>
              </div>
            </div>

            {/* <Translate /> */}
            {/* Hamburger icon for mobile */}
            <div
              className="hamburger"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-controls="nav-menu"
            >
              <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"}`}></i>
            </div>
          </div>
          <div className="logo-container">
            <img
              src={Logo}
              alt="LifeNStartups Logo"
              className="company-logo-round"
            />
          </div>
          {/* Normal navigation for larger screens */}
          <nav
            id="nav-menu"
            className={`nav-links ${isMenuOpen ? "active" : ""}`}
            ref={navRef}
            aria-label="Main Navigation"
          >
            {" "}
            <span className="nav-indicator" style={indicatorStyle}></span>
            <Link
              to="/"
              className={`nav-item home ${
                selected === "home" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("home");
                closeMenu();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                id="house"
              >
                <path
                  fill="black"
                  d="M30 15a1 1 0 0 1-.58-.19L16 5.23 2.58 14.81a1 1 0 0 1-1.16-1.62l14-10a1 1 0 0 1 1.16 0l14 10A1 1 0 0 1 30 15zM5 9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H6v3a1 1 0 0 1-1 1z"
                ></path>
                <path
                  fill="#101820"
                  d="M25 29h-5a1 1 0 0 1-1-1v-7h-6v7a1 1 0 0 1-1 1H7a3 3 0 0 1-3-3V16a1 1 0 0 1 2 0v10a1 1 0 0 0 1 1h4v-7a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v7h4a1 1 0 0 0 1-1V16a1 1 0 0 1 2 0v10a3 3 0 0 1-3 3Z"
                ></path>
              </svg>
              {/* मुख्य बातम्या */}
            </Link>
            {/* <div
            className={`nav-item about ${
              selected === "about" ? "selected" : ""
            }`}
            onMouseEnter={() => setSelected("about")}
            aria-haspopup="true"
          >
            Latest News
            <div className="dropdown">
              <Link to="/politics" onClick={closeMenu}>
                Politics
              </Link>
              <Link to="/entertainment" onClick={closeMenu}>
                Entertainment
              </Link>
              <Link to="/sports" onClick={closeMenu}>
                Sports
              </Link>
              <Link to="/classes" onClick={closeMenu}>
                Classes
              </Link>
            </div>
          </div> */}
            {/* <div
            className={`nav-item programs ${
              selected === "programs" ? "selected" : ""
            }`}
            onMouseEnter={() => setSelected("programs")}
            aria-haspopup="true"
          >
            Sports
            <div className="dropdown">
              <Link to="/football" onClick={closeMenu}>
                Football
              </Link>
              <Link to="/cricket" onClick={closeMenu}>
                Cricket
              </Link>
              <Link to="/hockey" onClick={closeMenu}>
                Hockey
              </Link>
            </div>
          </div> */}
            {/* <div
              className={`nav-item about ${
                selected === "about" ? "selected" : ""
              }`}
              onMouseEnter={() => setSelected("about")}
              aria-haspopup="true"
            >
              क्षेत्र
              <div className="dropdown">
                <Link to="/maharashtra" onClick={closeMenu}>
                  महाराष्ट्र
                </Link>
                <Link to="/marathwada" onClick={closeMenu}>
                  मराठवाडा
                </Link>
              </div>
            </div> */}
            {/* <div
              className={`nav-item region ${
                selected === "region" ? "selected" : ""
              }`}
              onMouseEnter={() => setSelected("region")}
              aria-haspopup="true"
            >
              जिल्हा
              <div className="dropdown">
                <Link to="/nanded" onClick={closeMenu}>
                  नांदेड-ग्रामीण
                </Link>
                <Link to="/nandedcity" onClick={closeMenu}>
                  नांदेड-शहर
                </Link>
              </div>
            </div> */}
            {/* <Link
            to="/Sports"
            className={`nav-item Sports ${
              selected === "Sports" ? "selected" : ""
            }`}
            onClick={() => {
              setSelected("Sports");
              closeMenu();
            }}
          >
            क्रीडा
          </Link> */}
            <Link
              to="/Rajya"
              className={`nav-item Rajya ${
                selected === "Rajya" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("Rajya");
                closeMenu();
              }}
            >
              राज्य
            </Link>
            <Link
              to="/nanded"
              className={`nav-item nanded ${
                selected === "nanded" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("nanded");
                closeMenu();
              }}
            >
              नांदेड जिल्हा
            </Link>
            <Link
              to="/nandedcity"
              className={`nav-item nandedcity ${
                selected === "nandedcity" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("nandedcity");
                closeMenu();
              }}
            >
              नांदेड शहर
            </Link>
            <Link
              to="/Politics"
              className={`nav-item Politics ${
                selected === "Politics" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("Politics");
                closeMenu();
              }}
            >
              राजकारण
            </Link>
            <Link
              to="/government"
              className={`nav-item    government ${
                selected === "government" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("government");
                closeMenu();
              }}
            >
              प्रशासकीय
            </Link>
            <Link
              to="/agri"
              className={`nav-item agri ${
                selected === "agri" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("agri");
                closeMenu();
              }}
            >
              शेती
            </Link>
            <Link
              to="/India"
              className={`nav-item India ${
                selected === "India" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("India");
                closeMenu();
              }}
            >
              देश
            </Link>
            <Link
              to="/contact"
              className={`nav-item contact ${
                selected === "contact" ? "selected" : ""
              }`}
              onClick={() => {
                setSelected("contact");
                closeMenu();
              }}
            >
              संपर्क
            </Link>
          </nav>
        </div>
        <img
          src={verified}
          alt="LifeNStartups Logo"
          className="verified-logo"
        />
      </div>
    </div>
  );
};

export default Header;
