import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import "./Crime.css";
import axios from "../../utils/axiosInstance";
import defaultImage from "../../Assets/LOGO-NAME.png";
import { ar } from "date-fns/locale";

const Crime = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/Crime");
        if (response.data && response.data.length > 0) {
          setNewsData(response.data.reverse());
        } else {
          setError("No news available in this category.");
        }
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div className="Crime-loading">Loading...</div>;
  if (error) return <div className="Crime-error">{error}</div>;

  const mainArticle = newsData[0];
  const subArticles = newsData.slice(1, 4); // First three sub-articles
  const additionalArticles = newsData.slice(4); // Remaining articles after the first three

  // Combine subArticles and additionalArticles into one array to display in chunks of 3
  const allArticles = [...subArticles, ...additionalArticles];

  // Helper function to chunk articles into groups of 3
  const chunkArticles = (articles, size) => {
    const result = [];
    for (let i = 0; i < articles.length; i += size) {
      result.push(articles.slice(i, i + size));
    }
    return result;
  };

  const articleChunks = chunkArticles(allArticles, 3); // Group articles into rows of 3

  return (
    <div className="Crime-container">
      <div className="Politics-line">
        <div className="Politics-header">
          <h1>नांदेड ग्रामीण</h1>
        </div>
      </div>
      <div className="Crime-content">
        {mainArticle && (
          <div className="Crime-main-article">
            <Link
              to={`/news/${mainArticle.news_id}`}
              className="Crime-main-article"
            >
              <img
                src={mainArticle.image_url || defaultImage}
                alt={mainArticle.title}
                className="Crime-main-image"
              />
              <div className="Crime-main-content">
                <p className="Crime-date">
                  {mainArticle.created_at
                    ? format(new Date(mainArticle.created_at), "MMMM d, yyyy")
                    : "Unknown Date"}
                </p>
                <h1 className="Crime-title">{mainArticle.title}</h1>
                <p className="Crime-description">{mainArticle.content}</p>
              </div>
            </Link>
          </div>
        )}

        {/* Display sub-articles and additional articles in 3-column layout */}
        <div className="Crime-articles">
          {articleChunks.map((chunk, chunkIndex) => (
            <div className="Crime-article-row" key={chunkIndex}>
              {chunk.map((article, index) => (
                <div className="Crime-sub-article" key={index}>
                  <Link
                    to={`/news/${article.news_id}`}
                    className="Crime-sub-article"
                  >
                    <img
                      src={article.image_url || defaultImage}
                      alt={article.title}
                      className="Crime-sub-image"
                    />
                    <p className="Crime-date">
                      {article.created_at
                        ? format(new Date(article.created_at), "MMMM d, yyyy")
                        : "Unknown Date"}
                    </p>
                    <p className="Crime-title">{article.title}</p>
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Crime;
