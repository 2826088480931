import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import axios from "axios"; // Make sure you have axios installed for API calls
import NewsManager from "./NewsManager";
import InstagramPostsManager from "./InstagramPostsManager";
import "./Dashboard.css"; // Import the CSS file

const Dashboard = ({ setIsAuthenticated }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [activeTab, setActiveTab] = useState("news");

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "https://api.nandedcitynews.in/api/logout"
      );
      if (response.status === 200) {
        localStorage.removeItem("token"); // Remove token from local storage
        setIsAuthenticated(false); // Update the state
        navigate("/login"); // Redirect to the login page
      }
    } catch (error) {
      console.error(
        "Logout failed:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome to the Dashboard</h1>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </header>
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "news" ? "active" : ""}`}
          onClick={() => setActiveTab("news")}
        >
          News Manager
        </button>
        <button
          className={`tab-button ${activeTab === "instagram" ? "active" : ""}`}
          onClick={() => setActiveTab("instagram")}
        >
          Instagram Posts Manager
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "news" && <NewsManager />}
        {activeTab === "instagram" && <InstagramPostsManager />}
      </div>
    </div>
  );
};

export default Dashboard;
