import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "./NewsSlider.css";
import axios from "../../../utils/axiosInstance";
import { formatDistanceToNow } from "date-fns"; // Import date-fns for time formatting

const NewsSlider = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      try {
        const response = await axios.get("/news/category/breaking");
        setNewsData(response.data);
        console.log(response.data);
      } catch (err) {
        console.error("Failed to fetch news:", err);
        setError("Failed to fetch news");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsByCategory();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Reverse the order to show latest news first
  const reversedNewsData = [...newsData].reverse();

  return (
    <div className="news-slider">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3500, // Time between each slide (in ms)
          disableOnInteraction: false, // Keeps autoplay running after interaction
        }}
        modules={[Autoplay, Navigation]} // Add Autoplay and Navigation modules
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {/* Group news data into chunks of three */}
        {Array.from({ length: Math.ceil(reversedNewsData.length / 3) }).map(
          (_, index) => (
            <SwiperSlide key={index}>
              <div className="news-gridss">
                {reversedNewsData
                  .slice(index * 3, index * 3 + 3)
                  .map((news, i) => (
                    <div
                      key={i}
                      className={`news-itemss ${
                        i === 0 ? "largess" : "smallss"
                      }`}
                    >
                      {" "}
                      <div className="news-image-wrapper">
                        <Link
                          to={`/news/${reversedNewsData[0].news_id}`}
                          className="main-news-link"
                        >
                          <img
                            src={news.image_url}
                            alt={news.title}
                            className="news-imagess"
                          />
                          <div className="red-overlay">
                            <div className="overlay-text">
                              {/* Add heading and time */}
                              <div className="news-headingss">
                                <span className="news-label">
                                  Nanded City News
                                </span>
                                <span className="news-time-ago">
                                  {formatDistanceToNow(
                                    new Date(news.created_at),
                                    {
                                      addSuffix: true,
                                    }
                                  )}
                                </span>
                              </div>
                              <span className="news-categoryss">
                                {news.category}
                              </span>
                              <h3 className="news-titless">{news.title}</h3>
                              {/* Add content preview */}
                              <p className="news-content">
                                {news.content.length > 100
                                  ? `${news.content.slice(0, 100)}...`
                                  : news.content}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  );
};

export default NewsSlider;
