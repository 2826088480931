import React, { useState, useEffect } from "react";
import axios from "../../utils/axiosInstance"; // Ensure this is pointing to your Axios instance
import InstagramEmbed from "../Homepage/InstagramPosts/InstagramEmbed"; // Adjust the path as necessary
import "./InstagramPostsManager.css"; // Import the CSS file

const InstagramPostsManager = () => {
  const [postUrl, setPostUrl] = useState("");
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to fetch all Instagram posts
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/instagramposts");
      setPosts(response.data);
      setError(null); // Reset error message
    } catch (err) {
      setError("Failed to fetch posts.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle adding a new post
  const handleAddPost = async (e) => {
    e.preventDefault();
    if (!postUrl) {
      setError("Post URL is required.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post("/instagramposts", {
        post_url: postUrl,
      });
      setPosts((prevPosts) => [...prevPosts, response.data]);
      setPostUrl(""); // Clear input field
      setError(null); // Reset error message
    } catch (err) {
      setError("Failed to add post.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle deleting a post
  const handleDeletePost = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/instagramposts/delete/${id}`);
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
      setError(null); // Reset error message
    } catch (err) {
      setError("Failed to delete post.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch posts on component mount
  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="instagram-posts-manager">
      <h1 className="instagram-posts-manager__header">
        Instagram Posts Manager
      </h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form className="instagram-posts-manager__form" onSubmit={handleAddPost}>
        <input
          type="text"
          className="instagram-posts-manager__input"
          value={postUrl}
          onChange={(e) => {
            setPostUrl(e.target.value);
            if (error) setError(null); // Clear error message on change
          }}
          placeholder="Enter Instagram Post URL"
        />
        <button
          type="submit"
          className="instagram-posts-manager__button"
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Post"}
        </button>
      </form>
      <h2>Existing Posts</h2>
      {loading ? (
        <p>Loading posts...</p>
      ) : (
        <ul className="instagram-posts-manager__post-list">
          {posts.map((post) => (
            <li key={post.id} className="instagram-posts-manager__post-item">
              <InstagramEmbed postUrls={[post.post_url]} />{" "}
              {/* Ensure post_url is passed as an array */}
              <button
                onClick={() => handleDeletePost(post.id)}
                className="instagram-posts-manager__button"
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete"}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InstagramPostsManager;
