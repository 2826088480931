import React from "react";
// import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { SlGlobe } from "react-icons/sl";
import { FaFacebookSquare, FaPhoneAlt } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
// import { SIGNUP } from "../../routes";
import EnquiryForm from "./EnquiryForm";

const ContactUs = () => {
  return (
    <div className="flex flex-col md:flex-row md:h-screen_desk">
      {/* Small screen banner */}
      <div className="relative flex flex-col gap-4 justify-center items-center md:hidden">
        {/* <img src={re_img} alt="contact-techb" className="w-full opacity-50" /> */}

        {/* <Link
          to={SIGNUP}
          className="absolute top-25 text-2xl underline text-secondary hover:text-primary hover:scale-105 transition-transform"
        >
          Sign Up Now!
        </Link> */}
      </div>

      {/* Large Screen vertical image */}

      <div className="w-full md:w-1/2 text-center mt-10 text-black px-6 md:px-12">
        <p className="text-3xl md:text-4xl font-semibold text-[#d2093c] mb-3">
          Contact
        </p>
        <div className="flex flex-col gap-2 items-center mb-8">
          <div className="absolute right-3 z-50 bottom-5 md:bottom-20 md:right-5 border-2 rounded-full p-2 text-[#d2093c] bg-primary hover:bg-secondary hover:scale-110 transition-transform">
            <a href="tel:+917507695758">
              <FaPhoneAlt />
            </a>
          </div>
          <a href="tel:+917507695758">
            <p className="flex gap-2 items-center">
              <IoCallOutline />
              <span className="text-highlight font-semibold">
                +९१ ७५०७६९५७५८
              </span>
            </p>
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="mailto:info@nandedcitynews.com"
          >
            <p className="flex gap-2 items-center">
              <MdOutlineMailOutline />
              <span className="text-highlight font-semibold">
                nandedcitynews.com
              </span>
            </p>
          </a>
          <a
            href="https://techbsoftwares.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="flex gap-2 items-center">
              <SlGlobe />
              <span className="text-highlight font-semibold">
                www.nandedcitynews.com
              </span>
            </p>
          </a>
          <div className="flex gap-4 items-center justify-center">
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <GrInstagram className="hover:text-pink-500 transition-colors" />
            </a>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebookSquare className="hover:text-blue-700 transition-colors" />
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaXTwitter
                className="hover:text-blue-400 transition-colors"
                rel="noreferrer noopener"
              />
            </a>
          </div>
        </div>
        <p className="text-2xl md:text-3xl font-semibold text-[#d2093c]">
          पत्ता
        </p>
        <p className="my-1 mb-4 font-semibold">नांदेड, महाराष्ट्र ४११७०५</p>
        <div className="flex justify-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120611.07958496203!2d77.31585495!3d19.147264250000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd1d66bb9d31721%3A0x43892c180155daf1!2sNanded%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1730145526078!5m2!1sen!2sin"
            width="400"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Maps of Nanded, Maharashtra"
          ></iframe>
        </div>
      </div>

      <div className="md:w-1/3 my-10">
        <p className="text-3xl md:text-4xl font-semibold text-[#d2093c] mb-4 text-center">
          चौकशी पाठवा
        </p>
        <EnquiryForm />
      </div>
    </div>
  );
};

export default ContactUs;
