import React from "react";
// import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { Spinner } from "../../../Components";
// import { postEnquiry } from "../../../Services/UserService";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Must be exactly 10 digits")
    .required("Required"),
  enquiry: Yup.string().required("Required"),
});

const EnquiryForm = () => {
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    enquiry: "",
  };

  const onSubmit = () => {
    console.log("hi");
  };
  // const onSubmit = (values, { setSubmitting, resetForm }) => {
  //     postEnquiry(values).then((response) => {
  //         if (response.status === 201) {
  //             toast.success("We have received your message. We will get back to you soon.");
  //         } else {
  //             toast.error("Failed! Please try again.");
  //         }
  //     }).finally(() => { setSubmitting(false); resetForm(); });
  // };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-4 flex flex-col w-full px-8 text-white">
          <div className="">
            <label htmlFor="name" className="font-semibold">
              Name
            </label>
            <div className="mt-2">
              <Field
                type="text"
                name="name"
                id="name"
                placeholder="Enter Your Name"
                required
                className="w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="email" className="font-semibold">
              Email address
            </label>
            <div className="mt-2">
              <Field
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="Enter Your Email"
                required
                className="w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="mobile" className="font-semibold">
              Mobile No
            </label>
            <div className="mt-2">
              <Field
                type="tel"
                name="mobile"
                id="mobile"
                placeholder="Enter Mobile No"
                required
                maxLength="10"
                className="w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary"
              />
              <ErrorMessage
                name="mobile"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="enquiry" className="font-semibold">
              Message
            </label>
            <div className="mt-2">
              <Field
                as="textarea"
                type="text"
                name="enquiry"
                id="enquiry"
                placeholder="Write Your Message"
                required
                className="w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary"
              />
              <ErrorMessage
                name="enquiry"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="rounded-md bg-sky-800 px-10 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
            >
              {!isSubmitting ? "Send" : ""}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EnquiryForm;
